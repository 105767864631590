/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import jwtDecode from "jwt-decode";
import { baseurl } from "../../../config/baseURL";

const base64UrlEncode = (str) => {
  let encoded = btoa(str)
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=+$/, "");
  return encoded;
};

// Slice Creates
const authSlice = createSlice({
  name: "auth",
  initialState: {}, // initial state
  reducers: {
    startLoadingData: (state) => {
      state.loading = true;
      state.error = "";
    },
    getTokenDataSuccessFully: (state, action) => {
      state.token = action?.payload;
      state.loading = false;
      state.error = "";
    },
    getUserInfoDataSuccessFully: (state, action) => {
      state.userInfo = action?.payload;
      state.loading = false;
      state.error = "";
    },
    failedLoadingData: (state, action) => {
      state.error = action?.payload;
      state.loading = false;
    },
    removeLoginData: (state) => {
      state.token = {};
      state.userInfo = {};
      state.loading = false;
      state.error = "";
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  startLoadingData,
  getTokenDataSuccessFully,
  getUserInfoDataSuccessFully,
  failedLoadingData,
  removeLoginData,
} = authSlice.actions;

// Api call for login
export const getAuthData = (code) => async (dispatch) => {
  dispatch(startLoadingData());
  if (code) {
    try {
      const { data } = await axios({
        method: "POST",
        url: `${baseurl}/api/sign-in`,
        // headers: {
        //   Authorization: `Basic ${base64UrlEncode("oidc-client:1234")}==`,
        // },
        params: {
          username: code?.username,
          password: code?.password,
        },
      });
      if (data) {
        dispatch(getTokenDataSuccessFully(data));
        localStorage.setItem("token", JSON.stringify({ access_token: data }));
        const decodeToken = jwtDecode(data);
        dispatch(getUserInfoDataSuccessFully(decodeToken));
        localStorage.setItem("userInfo", JSON.stringify(decodeToken));
        window.location.href = "/";
      }
    } catch (error) {
      dispatch(
        failedLoadingData(
          error?.response && error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.message
        )
      );
    }
  }
};

export const getReloadAuthData = () => async (dispatch) => {
  const token = JSON.parse(localStorage.getItem("token"));
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  dispatch(getTokenDataSuccessFully(token));
  dispatch(getUserInfoDataSuccessFully(userInfo));
};

export const logOut = (history) => async (dispatch) => {
  //delete cookie first then do other things
  localStorage.removeItem("token");
  localStorage.removeItem("userInfo");
  localStorage.clear();
  dispatch(removeLoginData());

  // window.location.reload();
};

export default authSlice.reducer;
