import { Oval, ThreeDots } from "react-loader-spinner";

export const LoadingLoader = ({ height = 25, width = 25 }) => (
  <Oval
    height={height}
    width={width}
    color="white"
    wrapperStyle={{}}
    wrapperClass=""
    visible={true}
    ariaLabel="oval-loading"
    secondaryColor="#4fa94d"
    strokeWidth={2}
    strokeWidthSecondary={2}
  />
);

export const LoadingThreeDots = ({ height = 25, width = 50 }) => (
  <ThreeDots
    height={height}
    width={width}
    radius="7"
    color="#4fa94d"
    ariaLabel="three-dots-loading"
    wrapperStyle={{}}
    wrapperClassName=""
    visible={true}
  />
);
