// import PropTypes from "prop-types";
// import ThemeProvider from "./ThemeContext";

// function ContextProvider({ children = null }) {
//   return <ThemeProvider>{children}</ThemeProvider>;
// }

// ContextProvider.propTypes = {
//   children: PropTypes.node.isRequired,
// };

// export default ContextProvider;

import CombinedProvider from "./Providers/CombinedProvider";
import NotificationProvider from "./Providers/NotificationContext";
import ThemeProvider from "./Providers/ThemeContext";

const AppProvider = ({ children = null }) => (
  <CombinedProvider components={[NotificationProvider, ThemeProvider]}>
    {children}
  </CombinedProvider>
);

export default AppProvider;
