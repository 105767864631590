import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import hideEyes from "../../assets/hideEyes.png";
import loginLogo from "../../assets/loginlogo.png";
import showEyes from "../../assets/showEyes.png";
import Suffix_logo from "../../assets/Suffix-Logo.png";
import ErrorValidate from "../../commom/ErrorValidate";
import { LoadingLoader } from "../../commom/LoadingLoader";
import { getAuthData } from "../../redux/slices/AuthSlice/authSlice";

function LoginScreen() {
  const [showPass, setShowPass] = useState(false);
  const { register, errors, handleSubmit } = useForm();
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();

  const authData = useSelector((state) => state?.authData);
  const { error } = authData;
  console.log(error);
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  const onSubmit = (apiData, e) => {
    e.preventDefault();
    dispatch(getAuthData(apiData));
  };
  console.log("getAuthData===>>>", authData?.loading);

  return (
    <>
      <div className="md:grid md:grid-cols-2 flex items-center justify-center  md:h-screen my-auto h-[90vh]">
        <div className=" md:flex items-center justify-end hidden ">
          <div className="">
            {/* <img
            src={loginlogoOne}
            alt="logoImage"
            className="max-w-xl  absolute mt-24 ml-80"
          /> */}
            <img src={loginLogo} alt="logoImage" className="max-w-xl " />
          </div>
        </div>
        <div className="flex items-center justify-center   ">
          <div className="flex items-center justify-center  rounded-lg border-2 shadow-md  py-10 px-10 min-h-[30rem] ">
            <div className="md:min-w-[25rem] ">
              <div className="  ">
                <img
                  className="mx-auto h-10 w-auto  "
                  src={Suffix_logo}
                  alt="Your Company"
                />
                <h2 className="my-5 text-blue-gray-500  text-center text-2xl font-bold leading-9 tracking-tight ">
                  Sign in to your account
                </h2>
                {authData?.error && <ErrorValidate error={authData?.error} />}
              </div>

              <div className=" ">
                {error?.response?.data?.error && (
                  <span className="text-red-700 py-5 flex justify-center font-medium bg-deep-orange-50 my-5">
                    {error?.response?.data?.error[0]}
                  </span>
                )}
                <form
                  className="space-y-6 text-white"
                  // action='#'
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div>
                    <label
                      htmlFor="email"
                      className="text-blue-gray-500  block text-sm font-medium leading-6  text-start"
                    >
                      User Name
                    </label>
                    <div className="mt-2">
                      <input
                        style={{ fontSize: "14px" }}
                        type="text"
                        placeholder="Username"
                        name="username"
                        ref={register}
                        required
                        className="border-b-2 p-0 border-x-gray-400 focus:border-light-blue-800 focus:outline-none   text-blue-gray-500  block w-full    py-1.5    placeholder:text-gray-400   sm:text-sm "
                      />
                    </div>
                  </div>

                  <div>
                    <div className="flex items-center justify-between">
                      <label
                        htmlFor="password"
                        className="text-blue-gray-500  block text-sm font-medium leading-6 "
                      >
                        Password
                      </label>
                      <div className="text-sm">
                        <a
                          href="#"
                          className="font-semibold text-blue-gray-500  "
                        >
                          Forgot password?
                        </a>
                      </div>
                    </div>
                    <div className="mt-2 flex items-center">
                      <input
                        style={{ fontSize: "14px" }}
                        type={showPassword ? "text" : "password"}
                        placeholder="Password"
                        name="password"
                        ref={register}
                        required
                        className="border-b-2 p-0 border-x-gray-400 focus:border-light-blue-800 focus:outline-none   text-blue-gray-500  block w-full    py-1.5    placeholder:text-gray-400   sm:text-sm "
                      />
                      {!showPassword && (
                        <img
                          className="max-w-[40px] max-h-6"
                          src={showEyes}
                          alt=""
                          onClick={handleTogglePassword}
                        />
                      )}

                      {showPassword && (
                        <img
                          className="max-w-[40px] max-h-6"
                          src={hideEyes}
                          alt=""
                          onClick={handleTogglePassword}
                        />
                      )}
                    </div>
                  </div>

                  <div className="flex gap-5 items-center">
                    <button
                      type="submit"
                      // onClick={handleHomeScreen}
                      className="flex   py-1.5 text-sm font-semibold leading-6 text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 bg-blue-600 px-8"
                    >
                      Sign in
                    </button>
                    {authData?.loading && <LoadingLoader />}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LoginScreen;
